<template>
  <a-modal :width="600" title="Tambah Supplier" :visible="visible" :closable="false">
    <a-spin :spinning="fetchingCreateSupplier" tip="Sedang membuat supplier, Mohon tunggu...">
      <template>
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-form-model-item
            label="Kode Supplier"
            prop="supplierCode"
          >
            <a-input
              v-model="form.supplierCode"
              placeholder="Masukan Kode Supplier"
            />
          </a-form-model-item>
          <a-form-model-item
            label="Nama Supplier"
            prop="supplierName"
          >
            <a-input
              v-model="form.supplierName"
              placeholder="Masukan Nama Supplier"
            />
          </a-form-model-item>
          <a-form-model-item
            label="Kota"
            prop="cityName"
          >
            <a-input
              v-model="form.cityName"
              placeholder="Masukan Kota"
            />
          </a-form-model-item>
          <a-form-model-item
            label="Alamat"
            prop="address"
          >
            <a-textarea
              v-model="form.address"
              placeholder="Masukan Alamat"
              rows="3"
            />
          </a-form-model-item>
          <a-form-model-item
            label="Email"
            prop="email"
          >
            <a-input
              v-model="form.email"
              placeholder="Masukan Email"
            />
          </a-form-model-item>
          <a-form-model-item
            label="No Telepon"
            prop="phone"
          >
            <a-input
              v-model="form.phone"
              placeholder="Masukan No Telepon"
            />
          </a-form-model-item>
        </a-form-model>
      </template>
    </a-spin>
    <template #footer>
      <template>
        <a-button
          :loading="fetchingCreateSupplier"
          @click="closeModal"
        >
          Batal
        </a-button>
        <a-button
          type="primary"
          :loading="fetchingCreateSupplier"
          @click="submitFormAddSupplier"
        >
          Tambah Supplier
        </a-button>
      </template>
    </template>
  </a-modal>
</template>

<script>
import { createSupplier } from '@/api/purchase-order'

const validatorField = (rule, value, callback, params) => {
  if ((value === null || value === '') && rule.required) {
    callback(new Error(`${params.fieldName} harus diisi`))
  } else if (value.length > params.max) {
    callback(new Error(`${params.fieldName} maksimal ${params.max} karakter`))
  } else {
    callback()
  }
}

export default {
  name: 'ModalAddSupplier',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    businessId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fetchingCreateSupplier: false,
      form: {
        supplierCode: '',
        supplierName: '',
        cityName: '',
        address: '',
        email: '',
        phone: '',
      },
      rules: {
        supplierCode: [{
          required: true,
          trigger: 'blur',
          validator: (rule, value, callback) => {
            validatorField(rule, value, callback, { fieldName: 'Kode Supplier', max: 50 })
          },
        }],
        supplierName: [{
          required: true,
          trigger: 'blur',
          validator: (rule, value, callback) => {
            validatorField(rule, value, callback, { fieldName: 'Nama Supplier', max: 150 })
          },
        }],
        cityName: [{
          required: false,
          trigger: 'blur',
          validator: (rule, value, callback) => {
            validatorField(rule, value, callback, { fieldName: 'Kota', max: 50 })
          },
        }],
        address: [{
          required: false,
          trigger: 'blur',
          validator: (rule, value, callback) => {
            validatorField(rule, value, callback, { fieldName: 'Alamat', max: 1000 })
          },
        }],
        email: [{
          required: false,
          trigger: 'blur',
          validator: (rule, value, callback) => {
            validatorField(rule, value, callback, { fieldName: 'Email', max: 150 })
          },
        }],
        phone: [{
          required: false,
          trigger: 'blur',
          validator: (rule, value, callback) => {
            validatorField(rule, value, callback, { fieldName: 'Nomor Telepon', max: 25 })
          },
        }],
      },
    }
  },
  computed: {
  },
  watch: {
    visible: {
      deep: true,
      immediate: false,
      handler(val) {
        if (!val) {
          this.$refs.ruleForm.resetFields()
        }
      },
    },
  },
  methods: {
    closeModal() {
      this.$emit('onCancel', false)
    },
    submitFormAddSupplier() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (!this.businessId) {
            return this.$notification.error({
              message: 'Gagal menambahkan supplier, business_id tidak ditemukan',
            })
          }
          this.fetchingCreateSupplier = true
          const {
            supplierCode,
            supplierName,
            cityName,
            address,
            email,
            phone,
          } = this.form
          const payload = {
            id: null,
            code: supplierCode,
            name: supplierName,
            city: cityName,
            address: address,
            email: email,
            phone: phone,
            business_id: this.businessId,
          }
          createSupplier({
            data: payload,
            business_id: this.businessId,
          })
          .then(() => {
            this.$notification.success({
              message: 'Berhasil menambahkan supplier',
            })
            this.fetchingCreateSupplier = false
            this.$emit('onCancel', true)
          })
          .catch(() => {
            this.$notification.error({
              message: 'Gagal menambahkan supplier',
            })
            this.fetchingCreateSupplier = false
          })
          this.fetchingCreateSupplier = false
        }
      })
    },
  },
}
</script>

<style lang="scss" module>
</style>
<style lang="scss">
.image-spin {
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}
</style>