<template>
  <a-modal width="800px" :visible="visible" :closable="false">
    <template v-if="revisedDataDuplicateSKU.length > 0">
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="mt-2 mb-3">
          Daftar Duplikat SKU
        </h5>
        <h6>Total: {{ revisedDataDuplicateSKU.length }}</h6>
      </div>
      <a-spin :spinning="isLoading" size="large" tip="Sedang mengunggah file...">
        <div class="mb-4">
          <a-table
            :columns="columns"
            :row-key="(record, index) => index"
            :data-source="revisedDataDuplicateSKU"
            :loading="isLoading"
            :pagination="false"
            :scroll="{ y: 330 }"
          >
            <template slot="status" slot-scope="text">
              <a-tag v-if="text.includes('duplicated_sku')" color="red">
                duplikat SKU
              </a-tag>
            </template>
          </a-table>
        </div>
      </a-spin>
    </template>
    <template v-if="revisedDataDownloadable.length > 0">
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="mt-2 mb-3">
          Daftar Revisi Item
        </h5>
        <h6>Total: {{ revisedDataDownloadable.length }}</h6>
      </div>
      <a-spin :spinning="isLoading" size="large" tip="Sedang mengunggah file...">
        <div>
          <a-table
            :columns="columns"
            :row-key="(record, index) => index"
            :data-source="revisedDataDownloadable"
            :loading="isLoading"
            :pagination="false"
            :scroll="{ y: 330 }"
          >
            <template slot="status" slot-scope="text">
              <a-tag v-if="text.includes('sku_not_found')" color="red">
                SKU tidak ditemukan
              </a-tag>
              <a-tag v-if="text.includes('duplicated_sku')" color="red">
                duplikat SKU
              </a-tag>
              <a-tag v-if="text.includes('uom_not_valid')" color="orange">
                UOM tidak valid
              </a-tag>
              <a-tag v-if="text.includes('data_not_valid')" color="purple">
                Data tidak valid
              </a-tag>
              <a-tag v-if="text.includes('price_not_valid')">
                Harga tidak valid
              </a-tag>
            </template>
          </a-table>
        </div>
      </a-spin>
    </template>
    <template #footer>
      <a-button key="back" class="mr-2" @click="typeof back !== 'undefined' && back(), onClose()">
        {{ revisedDataDownloadable?.length > 0 ? 'Tutup tanpa Unduh' : 'Tutup' }}
      </a-button>
      <a-button v-if="revisedDataDownloadable.length > 0" type="primary" class="mr-2" @click.prevent="downloadTemplate">
        <a-icon type="download" />
        Tutup dan Unduh Daftar Item yang Direvisi
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import getQueryParam from '@/utils/getQueryParam'
import XLSX from 'xlsx';
import { columnsRevisedDataGR } from '@/data/columns'

export default {
  name: 'ModalFeedbackAfterImport',
  components: {
  },
  mixins: [getQueryParam],
  props: {
    revisedData: Array,
    visible: Boolean,
    back: Function,
    onClose: Function,
    isLoading: Boolean,
  },
  data() {
    return {
      columns: columnsRevisedDataGR,
    }
  },
  computed: {
    revisedDataDownloadable() {
      return this.revisedData.filter((obj) => !obj.status.includes('duplicated_sku'))
    },
    revisedDataDuplicateSKU() {
      return this.revisedData.filter((obj) => obj.status.includes('duplicated_sku'))
    },
  },
  methods: {
    downloadTemplate() {
      const flattenData = this.revisedDataDownloadable.map((obj) => ({
        sku: obj.sku,
        hargabeli: obj.hargabeli,
        quantity: obj.quantity,
        uom: obj.uom,
      }))
      const rowData = [
        "SKU",
        "Harga Beli",
        "Quantity",
        "UOM",
      ]
      const worksheet = XLSX.utils.json_to_sheet(flattenData);
      const workbook = XLSX.utils.book_new()
      XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: "A1" });
      XLSX.utils.book_append_sheet(workbook, worksheet, 'data')
      XLSX.writeFile(workbook,'file_revised_data_po.xlsx')
      this.onClose()
    },
  },
}
</script>

<style lang="scss" module>
</style>