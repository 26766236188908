<template>
  <div>
    <div>
      <div class="d-flex align-items-center">
        <div>
          <h1 class="page-title">
            <a-icon type="arrow-left" @click="goBack" />
            Buat Pembelian
          </h1>
        </div>
      </div>
    </div>
    <a-spin :spinning="isLoading" tip="Mohon tunggu, sedang membuat PO...">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :class="{ info: isDetail }"
      >
        <a-card class="w-100 mt-4" :bordered="false">
          <a-row :gutter="24">
            <a-col :span="10">
              <a-form-model-item label="No. PO" prop="poNumber">
                <a-input
                  v-model.trim="form.poNumber"
                  size="large"
                  autocomplete="off"
                  placeholder="Masukan nomor PO"
                  allow-clear
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="10">
              <a-form-model-item label="Tanggal PO" prop="poDate">
                <a-date-picker
                  v-model="form.poDate"
                  size="large"
                  class="w-100"
                  :format="dateFormat"
                  placeholder="Masukan tanggal PO"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="10">
              <a-form-model-item label="Nomor Surat Jalan" prop="deliveryNumber">
                <a-input
                  v-model.trim="form.deliveryNumber"
                  size="large"
                  autocomplete="off"
                  placeholder="Masukan nomor surat jalan"
                  allow-clear
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="10">
              <a-form-model-item label="Tanggal Pengiriman" prop="deliveryDate">
                <a-date-picker
                  v-model="form.deliveryDate"
                  size="large"
                  class="w-100"
                  :format="dateFormat"
                  placeholder="Masukan tanggal pengiriman"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="10">
              <a-form-model-item label="Supplier" prop="supplierCode" class="mb-0">
                <a-select
                  v-model="form.supplierCode"
                  size="large"
                  class="select-antd-default"
                  placeholder="Pilih supplier"
                  :not-found-content="loadingSupplierList ? undefined : null"
                  @focus="fetchSupplierList"
                >
                  <a-spin v-if="loadingSupplierList" slot="notFoundContent" size="small" />
                  <a-select-option
                    v-for="item in supplierList"
                    :key="item.code"
                    :value="item.code"
                  >
                    {{ `[${item.code}] ${item.name}` }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <div class="mt-2" style="font-size: 12px">
                tidak menemukan supplier? <a style="color: #1890ff; cursor: pointer; text-decoration: underline" @click="showModalAddSupplier = true">Tambah Supplier</a>
              </div>
            </a-col>
            <a-col :span="10">
              <a-form-model-item :label="$t('sideBar.warehouse')" prop="warehouseId" class="mb-0">
                <a-select
                  v-model="form.warehouseId"
                  size="large"
                  class="select-antd-default"
                  placeholder="Pilih gudang"
                  @change="onChangeWarehouse"
                >
                  <a-select-option
                    v-for="item in warehouseList"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-card>

        <!-- Detail Item Produk -->
        <a-card title="Detail Item Produk" class="w-100 mt-4" :bordered="false">
          <a-spin :indicator="customIndicatorFormProduct" :spinning="!form.warehouseId">
            <a-row :gutter="24">
              <a-col :span="24">
                <div>
                  <div class="d-flex align-items-center justify-content-between mb-3">
                    <div class="d-flex">
                      <a-button
                        type="primary"
                        @click="addItem"
                      >
                        Tambah Item
                      </a-button>
                      <a-button
                        class="ml-2"
                        type="primary"
                        @click="showModalBulkItemImport = true"
                      >
                        Bulk Item Import
                      </a-button>
                    </div>
                    <div>Total SKU: <b>{{ computedValidSKU || 0 }}</b></div>
                  </div>
                  <a-table
                    :columns="columns"
                    :row-key="(record, index) => index"
                    :data-source="itemList"
                    :loading="isLoading"
                    :pagination="false"
                    :scroll="{ y: 330 }"
                  >
                    <template slot="action1" slot-scope="text, record">
                      <div
                        @click="onDeleteItem(record)"
                      >
                        <TrashIcon style="color: #E00000; cursor:pointer" />
                      </div>
                    </template>
                    <template slot="price" slot-scope="text, record, index">
                      <div class="d-flex align-items-center">
                        <div class="mr-2">
                          Rp
                        </div>
                        <a-input-number
                          v-model="record.price"
                          class="nostep w-75"
                          :disabled="!record.catalog_id"
                          size="large"
                          autocomplete="off"
                          :formatter="value => format(value)"
                          :parser="value => parseLocaleNumber(value)"
                          placeholder="0"
                          @keypress="numberOnly"
                          @change="onChangeInputCart($event, index, 'price')"
                        />
                      </div>
                    </template>
                    <template slot="catalog_id" slot-scope="text, record">
                      <a-select
                        v-model="record.catalog_id"
                        size="large"
                        class="select-antd-default"
                        style="width: 100%"
                        placeholder="Produk"
                        show-search
                        :label-in-value="false"
                        :not-found-content="fetching ? undefined : null"
                        :filter-option="false"
                        @change="getProductUOM($event, record)"
                        @blur="handleResetSearch"
                        @search="handleSearch"
                      >
                        <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                        <a-select-option
                          v-for="item in inventories"
                          :key="item.id"
                          :value="`${item.id}.${item.product_id}`"
                          :disabled="selectedSKU.includes(item.sku)"
                        >
                          <div class="d-flex flex-column product-select">
                            <div>{{ item?.title }}</div>
                            <div class="sku-desc">
                              {{ 'SKU : ' + ellipsisText(item?.sku) }}
                            </div>
                          </div>
                        </a-select-option>
                      </a-select>
                    </template>
                    <template slot="quantity" slot-scope="text, record">
                      <a-input
                        v-model="record.quantity"
                        :disabled="!record.catalog_id"
                        size="large"
                        type="number"
                        :min="0"
                        autocomplete="off"
                        placeholder="Qty"
                        @change="onChangeInputCart($event, index, 'quantity')"
                        @keypress="numberOnly"
                      />
                    </template>
                    <template slot="uom" slot-scope="text, record">
                      <a-spin :spinning="fetching">
                        <a-select
                          v-model="record.uom"
                          size="large"
                          class="select-antd-default"
                          placeholder="UoM"
                          :disabled="isDetail || !record.catalog_id"
                        >
                          <a-select-option v-for="level in record.levels" :key="level">
                            {{ level }}
                          </a-select-option>
                        </a-select>
                      </a-spin>
                    </template>
                    <template slot="batch_number" slot-scope="text, record">
                      <a-input
                        v-model="record.batch_number"
                        :disabled="!record.catalog_id"
                        size="large"
                        autocomplete="off"
                        placeholder="No. Batch"
                        allow-clear
                      />
                    </template>
                    <template slot="expired_date" slot-scope="text, record">
                      <a-date-picker
                        v-model="record.expired_date"
                        :disabled="!record.catalog_id"
                        size="large"
                        class="w-100"
                        :format="dateFormat"
                        placeholder="Expired Date"
                      />
                    </template>
                    <template slot="stock_type" slot-scope="text, record">
                      <a-select
                        v-model="record.stock_type"
                        size="large"
                        class="select-antd-default"
                        placeholder="Tipe Stok"
                        :disabled="isDetail || !record.catalog_id"
                      >
                        <a-select-option
                          v-for="item in stockList"
                          :key="item.id"
                          :value="item.id"
                        >
                          {{ item.label }}
                        </a-select-option>
                      </a-select>
                    </template>
                    <template slot="base_uom" slot-scope="text, record">
                      <a-input
                        v-model="record.base_uom"
                        size="large"
                        autocomplete="off"
                        placeholder="Base UoM"
                        allow-clear
                        disabled
                      />
                    </template>
                    <template slot="base_quantity" slot-scope="text, record">
                      <a-input
                        v-model="record.base_quantity"
                        size="large"
                        autocomplete="off"
                        placeholder="Base Qty"
                        allow-clear
                        disabled
                      />
                    </template>
                  </a-table>
                </div>
              </a-col>
            </a-row>
          </a-spin>
        </a-card>

        <div v-if="!isDetail" class="discount-footer">
          <a-button
            type="primary"
            size="large"
            class="mr-4"
            ghost
            :disabled="isLoading"
            @click="goBack"
          >
            {{ $t('utils.cancel') }}
          </a-button>
          <a-button
            type="primary"
            size="large"
            :loading="isLoading"
            @click="onSubmitCreatePO"
          >
            {{ $route.query.discount_id && !isDuplicate ? $t('utils.edit') : $t('utils.save') }}
          </a-button>
        </div>
      </a-form-model>
    </a-spin>
    <ModalBulkItemImport
      :is-loading="isLoading"
      :visible="showModalBulkItemImport"
      :on-close="() => showModalBulkItemImport = false"
      @handleSubmitModal="onNormalizeData"
    />
    <ModalFeedbackAfterImport
      :revised-data="listRevisedBulkItemImport"
      :is-loading="isLoading"
      :visible="showModalFeedbackAfterImport"
      :on-close="() => showModalFeedbackAfterImport = false"
    />
    <ModalAddSupplier
      :is-loading="isLoading"
      :visible="showModalAddSupplier"
      :business-id="businessId"
      @onCancel="showModalAddSupplier = false"
    />
  </div>
</template>

<script>
import { columnsCreatePO } from '@/data/columns'
import { useInputNumber } from '@/composable/useInputNumber'
import { useDateTime } from '@/composable/useDateTime'
import TrashIcon from '@/components/Icons/TrashIcon.vue'
import { getCatalogListGR, getUom } from '@/api/inventory'
import debounce from 'lodash/debounce'
import ModalBulkItemImport from '@/views/purchase-order/modal/ModalBulkItemImport.vue'
import ModalFeedbackAfterImport from '@/views/purchase-order/modal/ModalFeedbackAfterImport.vue'
import { useCurrency } from '@/composable/useCurrency'
import { getListSupplier, createPO } from '@/api/purchase-order'
import ModalAddSupplier from '@/views/supplier/ModalAddSupplier.vue'

export default {
  name: 'CreatePurchaseOrder',
  components: {
    TrashIcon,
    ModalBulkItemImport,
    ModalFeedbackAfterImport,
    ModalAddSupplier,
  },
  beforeRouteLeave(to, from, next) {
    next()
  },
  setup() {
    const { format, parseLocaleNumber } = useCurrency()
    const { numberOnly, toNumber } = useInputNumber()
    const { parseISODate } = useDateTime()
    return {
      format,
      parseLocaleNumber,
      numberOnly,
      toNumber,
      parseISODate,
    }
  },
  data() {
    return {
      defaultTier: 1,
      readyTier: 1,
      currTier: 1,
      currQuota: 0,
      sellerId: [],
      idPromo: -1,
      dateFormat: 'DD MMM YYYY',
      columns: columnsCreatePO,
      isLoading: false,
      isDetail: false,
      fetching: false,
      endOpen: false,
      visible: false,
      areaAll: false,
      distributorAll: false,
      categoryAll: false,
      customerAll: false,
      itemList: [],
      warehouseList: [],
      supplierList: [],
      loadingSupplierList: false,
      inventories: [],
      initVal: {},
      search: '',
      overlayStyle: {
        width: '100px',
      },
      params: {
        page: 1,
        size: 1000,
      },
      rules: {
        poNumber: [{
          required: true,
          trigger: 'blur',
          message: 'Nomor PO harus diisi',
        }],
        poDate: [{
          required: true,
          trigger: 'change',
          message: 'Tanggal PO harus diisi',
        }],
        deliveryDate: [{
          required: true,
          trigger: 'change',
          message: 'Tanggal Pengiriman harus diisi',
        }],
        supplierCode: [{
          required: true,
          trigger: 'blur',
          message: 'Supplier harus diisi',
        }],
        warehouseId: [{
          required: true,
          trigger: 'blur',
          message: 'Gudang harus diisi',
        }],
      },
      form: {
        poNumber: undefined,
        deliveryNumber: undefined,
        poDate: undefined,
        deliveryDate: undefined,
        supplierCode: undefined,
        warehouseId: undefined,
      },
      stockList: [
        {
          id: 'AV',
          label: 'Available',
        },
        {
          id: 'QR',
          label: 'Quarantine',
        },
        {
          id: 'DMG',
          label: 'Damage',
        },
        {
          id: 'WIP',
          label: 'Work In Progress',
        },
      ],
      showModalBulkItemImport: false,
      listRevisedBulkItemImport: [],
      showModalFeedbackAfterImport: false,
      customIndicatorFormProduct: (
        <div class="w-100" style="left: 0; margin: 0;">
          <a-alert class="w-50" style="margin-inline: auto;" v-if="!form.warehouseId" message="Harap pilih gudang sebelum menambahkan produk" type="warning" show-icon />
        </div>
      ),
      showModalAddSupplier: false,
    }
  },
  computed: {
    businessId() {
      return this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : this.$route.query.business_id
    },
    business() {
      return Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
    },
    isPrinciple() {
      return ['business owner', 'supervisor', 'store operator', 'principle'].includes(this.$store.state.user.role_name?.toLowerCase())
    },
    selectedSKU() {
      return this.itemList.map((obj2) => obj2.sku)
    },
    computedValidSKU() {
      return this.itemList.filter((obj) => obj.catalog_id)?.length
    },
  },
  watch: {
    businessId() {
      this.init()
    },
    search: {
      deep: true,
      immediate: false,
      handler: function () {
        this.fetchInventory()
      },
    },
  },
  created() {
    this.init()
  },
  methods: {
    init: debounce(function() {
      this.fetchWarehouseList()
    }, 500),
    filterOption(input, option) {
      const itemTitle = option.componentOptions.children[0].text;
      const itemSKU = option.componentOptions.children[2].text;
      const keyword = input.toLowerCase();
        
      return (
        itemTitle.toLowerCase().indexOf(keyword) !== -1 ||
        itemSKU.toLowerCase().indexOf(keyword) !== -1
      )
    },
    onChangeWarehouse() {
      this.fetchInventory()
    },
    onChangeInputCart(value, index, field) {
      const currentItemList = this.itemList[index]
      if (field === 'quantity' && value > 9999) {
        value = 9999
      }
      currentItemList[field] = value
      currentItemList.total_price = currentItemList.price * (currentItemList?.quantity || 0)
    },
    ellipsisText(text) {
      if(!text) return '-'
      return text.length > 29 ? `${text.substring(0, 26)}...` : text
    },
    onSubmitCreatePO() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.itemList.length === 0) {
            return this.$notification.error({
              message: 'Maaf, gagal membuat PO.',
              description: 'Tambahkan produk terlebih dahulu, minimal 1 produk.',
            })
          }

          this.isLoading = true
          let isPriceAndQuantityEmpty = false
          const {
            poNumber,
            deliveryNumber, 
            poDate,
            deliveryDate,
            warehouseId,
            supplierCode,
          } = this.form
          const payload = {
            id: null,
            number: poNumber,
            po_date: poDate,
            request_delivery_date: deliveryDate,
            supplier_code: supplierCode,
            supplier_do: deliveryNumber,
            warehouse_id: warehouseId,
            business_id: this.businessId,
            items: this.itemList.map(v => {
              if (!v.price || v.price === 0 || !v.quantity || v.quantity === 0) {
                isPriceAndQuantityEmpty = true
              }
              const data = {
                // sku: v.sku,
                // description: v.catalog_title,
                sku: 'JAPBUNDLING',
                description: 'Jap Bundling Daily Pouch DEF07',
                qty: v.quantity,
                // uom: v.uom,
                uom: 'PCS',
                price: v.price,
              }
              return data
            }),
          }

          if (isPriceAndQuantityEmpty) {
            this.isLoading = false
            return this.$notification.error({
              message: 'Maaf, gagal membuat PO.',
              description: 'Harga dan jumlah produk tidak boleh kosong.',
            })
          }

          await createPO({
            business_id: this.businessId,
            data: payload,
          })
          .then(() => {
            this.isLoading = false
            this.$notification.success({
              message: 'PO Berhasil Dibuat',
            })
            this.goBack()
          })
          .catch((err) => {
            console.error({err})
            this.$notification.error({
              message: err?.response?.data?.message || 'Maaf, gagal membuat PO.',
            })
            this.isLoading = false
          })

        }
      })
    },
    async fetchInventory() {
      this.isLoading = true
      const bodyValue = {
        business_id: this.businessId,
        warehouse_id: this.form.warehouseId,
        search: this.search,
        page: 1,
        limit: 20,
      }
      await getCatalogListGR(bodyValue)
        .then(({ data: { data: response } }) => {
          this.inventories = response
        })
        .catch(err => {
          this.inventories = []
          console.error(err)
        })
        .finally(() => this.isLoading = false)
    },
    handleSearch: debounce(function(e) {
      this.search = e
    }, 500),
    handleResetSearch() {
      this.search = ''
    },
    fetchWarehouseList() {
      this.$store
        .dispatch('warehouse/GETWAREHOUSELIST', {
          ...this.$route.query,
          business_id: this.businessId,
        })
        .then(response => {
          this.warehouseList = response
          this.form.warehouseId = this.$route.query.warehouseId
        })
        .catch(err => {
          console.error(err)
          return err
        })
    },
    fetchSupplierList() {
      this.loadingSupplierList = true
      getListSupplier({
        business_id: this.businessId,
        params: {
          business_id: this.businessId,
        },
      })
      .then(({ data }) => {
        this.supplierList = data.data
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => this.loadingSupplierList = false)
    },
    async getProductUOM(value, record) {
      const findInventory = this.inventories.find(item => {
        return item.id === value.split('.')[0]
      })
      record.catalog_title = findInventory?.title || ''
      record.sku = findInventory?.sku || ''
      
      this.fetching = true
      await getUom({
        id: value.split('.')[1],
        params: {
          business_id: this.businessId,
        },
      })
      .then(({ data: { data: response } }) => {
        let levels = []
        response.units.forEach((item, index) => {
          levels.push(item.unit)
          record.units[item.unit] = {
            numerator: item.numerator,
            denumerator: item.denumerator,
          }

          if(index == 0) record.uom = item.unit
          if(item.numerator == 1 && item.denumerator == 1) record.base_uom = item.unit
        })
        record.levels = levels
      })
      .catch(() => {
        record.uom = ''
        record.base_uom = ''
        record.levels = []
        record.units = {}
      })
      .finally(() => {
        this.fetching = false
      })
    },
    onDeleteItem(record) {
      const filter = this.itemList.filter(v => {return v.item_reference_id !== record.item_reference_id})
      const temp = filter.map((v, i) => {
        const data = {
          ...v,
          item_reference_id: typeof v.item_reference_id === 'string' ? v.item_reference_id : i + 1,
        }
        return data
      })
      this.itemList = temp
    },
    onNormalizeData(importedData) {
      this.isLoading = true
      this.listRevisedBulkItemImport = []
      const normalizeData = []
      importedData.map((obj) => {
        const status = []
        const newObj = Object.fromEntries(
          Object.entries(obj).map(([key, val]) => [key.replace(/\s/g, '').toLowerCase(), val]),
        );
        const isDuplicatedSKU = normalizeData.find((obj2) => obj2.sku === newObj.sku)
        if (isDuplicatedSKU) {
          status.push('duplicated_sku')
        }
        if (!/^\d+$/.test(newObj.quantity) || !newObj?.uom || !newObj?.sku || !newObj?.hargabeli) {
          status.push('data_not_valid')
        }
        const num = Number(newObj?.hargabeli)
        if (!(!isNaN(num) && typeof num === 'number' && num > 0)) {
          status.push('price_not_valid')
        }
        if (status.length) {
          this.listRevisedBulkItemImport.push({
            ...newObj,
            status: status,
          })
          return
        }
        normalizeData.push(newObj)
      })
      this.onSubmitBulkItemImport(normalizeData)
    },
    onSubmitBulkItemImport(importedData) {
      const promises = []
      const tempItemList = this.itemList
      importedData.map((obj) => {
        const isDuplicatedSKU = tempItemList.find((obj2) => obj2.sku === obj.sku)
        if (isDuplicatedSKU) {
          return
        }
        const bodyValue = {
          business_id: this.businessId,
          warehouse_id: this.form.warehouseId,
          search: obj.sku,
          page: 1,
          limit: 20,
        }
        promises.push(
          getCatalogListGR(bodyValue),
        )
      })

      Promise.all(promises)
        .then(async (res) => {
          await Promise.all(res.map(async (obj, index) => {
            const { data, total_row } = obj.data
            if (total_row > 0) {
              const findSpecificCatalog = data.find((item) => item.sku === importedData[index].sku)
              if (findSpecificCatalog) {
                const selecetedCatalog = findSpecificCatalog
                const newRecord = {
                  item_reference_id: tempItemList.length + index,
                  catalog_id: `${selecetedCatalog.id}.${selecetedCatalog.product_id}`,
                  catalog_title: selecetedCatalog.title,
                  quantity: importedData[index].quantity,
                  uom: importedData[index].uom,
                  sku: importedData[index].sku,
                  price: importedData[index].hargabeli,
                  batch_number: '',
                  expired_date: null,
                  stock_type: 'AV',
                  base_quantity: '',
                  base_uom: '',
                  serial_number: '',
                  levels: [],
                  units: {},
                }
                const { data: { data: uomLevels } } = await getUom({
                  id: selecetedCatalog.product_id,
                  params: {
                    business_id: this.businessId,
                  },
                })
                if (uomLevels) {
                  let levels = []
                  uomLevels.units.forEach((item, index) => {
                    levels.push(item.unit)
                    newRecord.units[item.unit] = {
                      numerator: item.numerator,
                      denumerator: item.denumerator,
                    }
                    if(item.numerator == 1 && item.denumerator == 1) newRecord.base_uom = item.unit
                  })
                  newRecord.levels = levels
                } else {
                  newRecord.uom = ''
                  newRecord.base_uom = ''
                  newRecord.levels = []
                  newRecord.units = {}
                }
                const isValidUOM = newRecord.levels.find((obj) => obj === newRecord.uom)
                if (isValidUOM) {
                  const isExistOnInventories = this.inventories.find((obj) => obj.id === selecetedCatalog.id)
                  if (!isExistOnInventories) {
                    this.inventories.push(selecetedCatalog)
                  }
                  this.itemList.push(newRecord)
                  this.onChangeQuantity(`${newRecord.quantity}`, newRecord)
                } else {
                  this.listRevisedBulkItemImport.push({
                    ...importedData[index],
                    status: ['uom_not_valid'],
                  })
                }
              } else {
                this.listRevisedBulkItemImport.push({
                  ...importedData[index],
                  status: ['sku_not_found'],
                })
              }
            } else {
              this.listRevisedBulkItemImport.push({
                ...importedData[index],
                status: ['sku_not_found'],
              })
            }
          }))
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.showModalBulkItemImport = false
          this.isLoading = false
          if (this.listRevisedBulkItemImport.length) {
            this.showModalFeedbackAfterImport = true
            this.$notification.warning({
              message: 'Beberapa data tidak valid',
            })
          } else {
            this.$notification.success({
              message: 'Semua Data Berhasil di Import',
            })
          }
        })
    },
    onChangeQuantity(event, record) {
      record.base_quantity = +event * +record.units[record.uom].numerator
    },
    addItem() {
      const arr = this.itemList
      const temp = {
          item_reference_id: arr.length + 1,
          catalog_id: '',
          catalog_title: '',
          quantity: '',
          uom: '',
          sku: '',
          batch_number: '',
          expired_date: null,
          stock_type: 'AV',
          base_quantity: '',
          base_uom: '',
          serial_number: '',
          levels: [],
          units: {},
          price: null,
          total_price: 0,
      }
      arr.push(temp)
      this.itemList = arr
    },
    goBack() {
      this.$router.back({
        path: '/inventory/goods-receipt',
        query: { ...this.$route.query },
      })
    },
  },
}
</script>

<style lang="scss">
@import './create.module.scss';
</style>
<style lang="scss" scoped>
.ant-select-lg .ant-select-selection--single .product-select {
  white-space: nowrap !important;
}
.product-select {
  white-space: pre-wrap !important;
  .sku-desc {
    font-size: 12px;
    font-weight: 500;
  }
}
</style>
