<template>
  <a-modal width="600px" :visible="visible" :closable="false">
    <h5 class="mt-2">
      Import Items
    </h5>
    <a-spin :spinning="isLoading" size="large" tip="Sedang mengunggah file...">
      <div>
        <a-upload-dragger
          class="ml-2"
          :file-list="uploadedFile"
          :remove="onRemove"
          :before-upload="beforeUpload"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="plus" />
          </p>
          <p class="ant-upload-text">
            Upload file excel anda disini
          </p>
          <p class="ant-upload-hint p-2">
            Jika belum memiliki template file import items, silahkan klik tombol download file di kiri bawah.
          </p>
        </a-upload-dragger>
      </div>
    </a-spin>
    <template #footer>
      <div class="d-flex justify-content-between">
        <a-button type="link" class="mr-2" :disabled="isLoading" @click.prevent="downloadTemplate">
          <a-icon type="download" />
          Download Template
        </a-button>
        <div>
          <a-button key="back" :disabled="isLoading" class="mr-2" @click="typeof back !== 'undefined' && back(), onClose()">
            Cancel
          </a-button>
          <a-button key="submit" :disabled="isLoading || !uploadedFile.length > 0 || !jsonData.length > 0" type="primary" @click="onSubmitModal()">
            Submit All Items
          </a-button>
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script>
import getQueryParam from '@/utils/getQueryParam'
import XLSX from 'xlsx';

export default {
  name: 'ModalBulkItemImport',
  components: {
  },
  mixins: [getQueryParam],
  props: {
    visible: Boolean,
    back: Function,
    onClose: Function,
    isLoading: Boolean,
  },
  data() {
    return {
      jsonData: [],
      uploadedFile: [],
    }
  },
  computed: {
  },
  methods: {
    downloadTemplate() {
      const rowData = [
        "SKU",
        "Harga Beli",
        "Quantity",
        "UOM",
      ]
      const worksheet = XLSX.utils.json_to_sheet([]);
      const workbook = XLSX.utils.book_new()
      XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: "A1" });
      XLSX.utils.book_append_sheet(workbook, worksheet, 'data')
      XLSX.writeFile(workbook,'file_template_bulk_items_po.xlsx')
    },
    fileToJson (file) {
      const promise = new Promise((resolve) => {
        const reader = new FileReader()
        reader.onload = async function (e) {
          var data = e.target.result
          var workbook = XLSX.read(data, { type: 'binary' })
          let sheetName = workbook.SheetNames[0]
          let worksheet = workbook.Sheets[sheetName]
          let rowObject = XLSX.utils.sheet_to_row_object_array(worksheet)
          const finalJsonData = await JSON.stringify(rowObject, undefined, 4)
          resolve(finalJsonData);
        }
        reader.readAsBinaryString(file)
      });

      promise
        .then((res) => {
          this.jsonData = JSON.parse(res)
        });
    },
    beforeUpload(file) {
      this.uploadedFile = [file]
      this.fileToJson(file)
      return false
    },
    onRemove() {
      this.jsonData = []
      this.uploadedFile = []
    },
    onSubmitModal() {
      this.$emit('handleSubmitModal', this.jsonData)
      this.onRemove()
    },
  },
}
</script>

<style lang="scss" module>
</style>