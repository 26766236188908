import apiClient from '@/services/axios'

export const getListPO = ({ business_id, params }) => {
  return apiClient({
    method: 'get',
    url: '/goodreceive/query/purchase-order',
    headers: {
      'Business-Id': business_id,
    },
    params,
  })
}

export const getDetailPO = ({ business_id, id }) => {
  return apiClient({
    method: 'get',
    url: `/goodreceive/query/purchase-order/${id}`,
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const createPO = ({ business_id, data }) => {
  return apiClient({
    method: 'post',
    url: '/goodreceive/cmd/purchase-order',
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const deletePO = ({ business_id, data }) => {
  return apiClient({
    method: 'post',
    url: '/goodreceive/cmd/purchase-order/cancel',
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const getListSupplier = ({ business_id, params }) => {
  return apiClient({
    method: 'get',
    url: '/goodreceive/query/supplier',
    headers: {
      'Business-Id': business_id,
    },
    params,
  })
}

export const createSupplier = ({ business_id, data }) => {
  return apiClient({
    method: 'post',
    url: '/goodreceive/cmd/supplier',
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}
